var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-1 pt-0"},[(_vm.$vuetify.breakpoint.lgAndDown)?_c('v-carousel',{attrs:{"cycle":"","height":"50vh","show-arrows":false,"hide-delimiters":""}},_vm._l((_vm.urls),function(url,i){return _c('v-carousel-item',{key:i,attrs:{"src":url}})}),1):_vm._e(),(_vm.$vuetify.breakpoint.xl && !_vm.isDark)?_c('v-carousel',{attrs:{"cycle":"","height":"50vh","show-arrows":false,"hide-delimiters":""}},_vm._l((_vm.urls_xl_light),function(url,i){return _c('v-carousel-item',{key:i,attrs:{"src":url}})}),1):_vm._e(),(_vm.$vuetify.breakpoint.xl && _vm.isDark)?_c('v-carousel',{attrs:{"cycle":"","height":"50vh","show-arrows":false,"hide-delimiters":""}},_vm._l((_vm.urls_xl_dark),function(url,i){return _c('v-carousel-item',{key:i,attrs:{"src":url}})}),1):_vm._e(),_c('v-container',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-btn',{staticClass:"primary--text",attrs:{"block":"","outlined":"","x-large":"","href":"https://www.arbetsmarknad.futf.se"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-web")]),_vm._v(" Arbetsmarknadsutskottets hemsida ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-btn',{staticClass:"primary--text",attrs:{"block":"","outlined":"","x-large":"","href":"https://docs.google.com/forms/d/e/1FAIpQLSciRk6c_CtJbuTJhZUVgcoK0rPiEwnChwdgnQby9P4Ck-oiXA/viewform","target":"_blank"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-hand-heart")]),_vm._v(" Trygghetsformuläret! ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"primary--text",attrs:{"block":"","outlined":"","x-large":"","href":"http://eepurl.com/gi8Xir","target":"_blank"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email")]),_vm._v(" FUTFs nyhetsbrev! ")],1)],1)],1)],1),_c('v-divider'),_c('v-container',{staticClass:"pt-0 px-2"},[_c('v-row',[(
          _vm.$vuetify.breakpoint.xsOnly ||
          _vm.$vuetify.breakpoint.smOnly ||
          _vm.$vuetify.breakpoint.mdOnly
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('infocards')],1):_vm._e(),_vm._l((_vm.posts),function(post,i){return _c('v-col',{key:i,attrs:{"cols":12}},[_c('newscard',_vm._b({attrs:{"language":_vm.currentLang}},'newscard',post,false))],1)}),(
          _vm.$vuetify.breakpoint.xsOnly ||
          _vm.$vuetify.breakpoint.smOnly ||
          _vm.$vuetify.breakpoint.mdOnly
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('sponsorlist')],1):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }